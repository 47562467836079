import type { RouteLocationNormalized } from 'vue-router'

export default (to: RouteLocationNormalized): boolean => {
    const pagePath = to.fullPath

    // Чи планується перенаправлення
    if ((pagePath === '/') || pagePath.startsWith('/?')) {
        return false
    }

    // Чи це зовнішня сторінка
    if (pagePath.startsWith('/external/')) {
        return false
    }

    return true
}
